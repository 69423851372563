var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.headers,"items":_vm.items,"custom-filter":_vm.customFilter,"search":_vm.searchString,"item-key":"id","items-per-page":-1,"disable-pagination":"","hide-default-footer":""},on:{"click:row":_vm.setDetailNode},scopedSlots:_vm._u([(_vm.isModOrAdmin)?{key:"item.modifiedCol",fn:function(ref){
var item = ref.item;
return [(item.modified)?_c('v-icon',[_vm._v("mdi-pencil-outline")]):_vm._e()]}}:null,{key:"item.nameCol",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"padding-top":"5px","padding-bottom":"5px"}},[_c('p',{staticClass:"title-text"},[_vm._v(_vm._s(item.name))]),(_vm.isModOrAdmin && item.serialNumber)?_c('i',[_vm._v(_vm._s(item.serialNumber))]):_vm._e()])]}},{key:"item.retrievalOrPlacementDateCol",fn:function(ref){
var item = ref.item;
return [(item.retrievalOrPlacementIcon)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"grey lighten-4 info-chip",staticStyle:{"width":"115px"},attrs:{"label":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":"","color":"black text--lighten-3"}},[_vm._v(" "+_vm._s(item.retrievalOrPlacementIcon)+" ")]),_vm._v(" "+_vm._s(item.retrievalOrPlacementDate)+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.retrievalOrPlacementTooltip))])]):_vm._e()]}},(_vm.isModOrAdmin)?{key:"item.retrievalStatusCol",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"grey lighten-4 info-chip",attrs:{"label":""}},'v-chip',attrs,false),on),[_c('v-row',{attrs:{"dense":""}},[_c('div',{attrs:{"display":"inline"}},[_vm._v(" "+_vm._s(item.retrievalStatus)+" "),_c('v-icon',{staticClass:"mr-0",attrs:{"color":item.retrievalStatusColor,"small":"","right":""}},[_vm._v(" mdi-circle ")])],1)])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.retrievalStatusDescription))])])]}}:null,(_vm.isModOrAdmin)?{key:"item.bookmarkCol",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{class:_vm.isInComparison(item.node) ? 'remove-button' : 'add-button',attrs:{"small":"","icon":"","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleComparison(item.node)}}},[(_vm.isInComparison(item.node))?_c('v-icon',[_vm._v(" mdi-bookmark-minus ")]):_c('v-icon',[_vm._v(" mdi-bookmark-plus ")])],1)]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }